import { render, staticRenderFns } from "./main-nav.vue?vue&type=template&id=6119fd2c&scoped=true&"
import script from "./main-nav.vue?vue&type=script&lang=js&"
export * from "./main-nav.vue?vue&type=script&lang=js&"
import style0 from "./main-nav.vue?vue&type=style&index=0&id=6119fd2c&prod&lang=scss&scoped=true&"
import style1 from "./main-nav.vue?vue&type=style&index=1&id=6119fd2c&prod&v-if=settings.layout%20%26%26%20settings.layout%20%3D%3D%20'secondary'&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6119fd2c",
  null
  
)

export default component.exports