<template>
  <div class="item">
    <div class="custom-control custom-checkbox ml-n2">
      <input type="checkbox" class="custom-control-input" :id="`selecteditem-${item.slug}`" v-model="selected" />
      <label style="height: 20px" class="custom-control-label" :for="`selecteditem-${item.slug}`"></label>
    </div>
    <div class="img-wrapper d-flex align-items-center justify-content-center mr-2 mr-sm-3">
      <img :src="item.image_url || '/images/default-img.svg'" class="img-fluid">
    </div>
    <div>
      <div>
        <router-link class="name text-dark" :to="{ name: 'products-slug', params: { slug: item.slug } }">
          {{ item.title | styleTitle }}
        </router-link>
      </div>
      <div class="info">
        <div>
          <div v-if="settings.cart.showUnitPrice" class="price text-primary font-weight-bold">
            <span v-if="$store.state.settings.products.currencyPrefix">{{$store.state.settings.products.currencyPrefix}}</span>${{ item.price }}
            <span v-if="item.extra && JSON.parse(item.extra).unit" class="small">
              <span class="font-weight-bold small" :style="{ color: settings.colors.priceColor ? settings.colors.priceColor : ''}"> {{ JSON.parse(item.extra).unit }}</span>
            </span>
          </div>
          <div v-else class="price text-primary font-weight-bold">
            <span v-if="$store.state.settings.products.currencyPrefix">{{$store.state.settings.products.currencyPrefix}}</span>${{ item.line_price }}
          </div>
          <div class="ml-2 font-weight-bold" v-if="readonly">({{ item.quantity }})</div>
        </div>
        <div v-if="!readonly" class="order-controls">
          <change-quantity
            :qty="item.quantity"
            :special="special"
            :cart-item="item"
            :limit="item.num_inventory"
            :ui-limit="item.num_inventory"
            :showRemoveItem="item.quantity > 0" />
          <div v-if="item.extra && JSON.parse(item.extra).max_order_quantity" class="order-limit">
            Order limit: <strong>{{JSON.parse(item.extra).max_order_quantity}}</strong>
          </div>
          <div v-if="item.settings.disableDelivery && item.settings.disableDeliveryText && item.settings.disableDeliveryText.length > 0" class="text-small">
            <small><b class="text-danger">Delivery: </b>{{item.settings.disableDeliveryText}}</small> 
          </div>
          <div v-if="item.settings.disableShipping && item.settings.disableShippingText && item.settings.disableShippingText.length > 0" class="text-small">
            <small><b class="text-danger">Shipping: </b>{{item.settings.disableShippingText}}</small> 
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  export default {
    name: 'cartItem',
    props: {
     item: {
       required: true
     },
     readonly: {
       default: false
     },
     special: {
       default: false
     }
    },
    data() {
      return {
        selected: false
      };
    },
    computed: {
      settings() {
        return this.$store.state.settings;
      }
    },
    methods: {
      urlFriendly(value) {
        return this.$ezSlugify(value);
      }
    },
    watch: {
      selected() {
        this.$emit('onSelect', this.item.id, this.selected);
      }
    }
  };

</script>

<style lang="scss" scoped>
  .item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    > div:last-child {
      width: 100%;
    }

    .img-wrapper {
      border: 1px solid #E8E8E8;
      border-radius: 4px;
      width: 100px;
      min-width: 100px;
      height: 100px;
      img {
        max-height: 90px;
        max-width: 90px;
      }
    }
    .name {
      color: var(--text);
      font-size: 1rem;
      text-overflow: ellipsis;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 16px;
      width: 100%;
    }

    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .price {
        font-size: 1.125rem;
      }

      :deep(.change-quantity) {
        justify-content: flex-end;
      }
    }

    &.small {
      .name {
        font-size: 14px;
      }
      .img-wrapper {
        width: 70px;
        height: 70px;
        min-width: 70px;
        img {
          max-width: 68px;
          max-height: 68px;
        }
      }
    }
  }

  .order-controls {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .order-limit {
      font-size: 13px;
      margin-top: 8px;
    }
  }

  @media (max-width: 575px) {
    :deep(.add-remove) {
      max-width: 190px;
    }
    .item {
      .img-wrapper {
        width: 70px;
        height: 70px;
        min-width: 70px;
        img {
          max-width: 68px;
          max-height: 68px;
        }
      }
      .name {
        font-size: 0.875rem;
        line-height: 1rem;
        font-weight: 500;
        margin-bottom: 6px;
      }
      .info {

        .price {
          margin-bottom: 6px;
        }

        :deep(.change-quantity) {
          .add-cart {
            b,
            div {
              display: none;
            }
          }

          input {
            max-width: 32px;
          }
        }
      }
    }
  }

  @media (max-width: 428px) {
    .item {
      .info {
        flex-direction: column;
        align-items: flex-start;

        :deep(.change-quantity) {
          justify-content: flex-start;
        }
      }
    }

    .order-controls {
      align-items: flex-start;
    }
  }
</style>
