import CartApiService from '@/api-services/cart.service';
import BannerApiService from '@/api-services/banner.service';
import SearchApiService from '@/api-services/search.service';
import RentalApiService from '@/api-services/rental.service';
import HomePageApiService from '@/api-services/homepage.service';
import DepartmentsService from '@/api-services/departments.service';
import WidgetsApiService from '@/api-services/widgets.service';
import AdminApiService from '@/api-services/admin.service';

export default {
  fetchBanner({ commit }) {
    BannerApiService.getBanner().then((res) => {
      commit('setBanner', res.data.data);
    });
  },

  fetchCartItemsDetails({ state, commit }) {
    const activeUser = state.activeUser;
    if (activeUser && activeUser.is_admin) {
      return;
    }
    return CartApiService.cartDetails().then((res) => {
      commit('setCartItems', res.data.data);
    });
  },

  async fetchBusinessInfo({state, commit}) {
    if(state.businessDetails) {
      commit('setBusinessInfo', state.businessDetails.about_us);
    } else {
      let res = await HomePageApiService.getBusinessDetails();
      commit('setBusinessDetails', res.data.data);
      if(res.data.data.business)
        commit('setBusinessInfo', res.data.data.business.about_us);
    }
  },

  async searchSuggestion(context, key) {
    const handleRes = (resData) => {
      resData.data.brands.items.map(e => {
        e.original_brand = e.brand_name;
        e.brand_name = e.brand_name.toLowerCase();
      });
      resData.data.departments.items.map(e => e.name = e.name.toLowerCase());
      resData.data.products.items.map(e => {
        e.original_title = e.title;
        e.title = e.title.toLowerCase();
      });
      resData.data.top_search_terms && resData.data.top_search_terms.items.map(e => {
        e.original_title = e.title;
        e.title = e.title.toLowerCase();
      });
      context.commit('saveSearchSuggestions', resData.data);
    };

    const cacheKey = "suggestions:" + key;
    const cachedValue = context.getters.getCachedValue(cacheKey);

    const triggerSearch = key.length > 2 ? true : false;

    if (cachedValue && triggerSearch) {
      handleRes(cachedValue);
      return Promise.resolve(cachedValue);
    }

    if(triggerSearch) {
      const sugg = await SearchApiService.searchSuggestions(key).then((res) => {
        return res;
      });

      const searchTermSugg = await SearchApiService.getSearchTermSuggestions(key).then((res) => {
        return res;
      });

      sugg.data.data.top_search_terms = searchTermSugg.data.data ? searchTermSugg.data.data.top_search_terms : null;
      context.commit('setCacheValue', {key: cacheKey, data: sugg.data, expiresIn: 60});
      handleRes(sugg.data);
    }
  },

  searchBrandProductSuggestion(context, key) {
    const handleRes = (resData) => {
      resData.data.map(e => {
        e.original_title = e.name;
        e.title = e.name.toLowerCase();
      });
      context.commit('saveSearchBrandProductSuggestions', resData.data);
    };

    const cacheKey = "brandProductSuggestions:" + key;
    const cachedValue = context.getters.getCachedValue(cacheKey);
    if ( cachedValue ) {
      handleRes(cachedValue);
      return Promise.resolve(cachedValue);
    }
    AdminApiService.searchBrandProducts(key).then((res) => {
      context.commit('setCacheValue', {key: cacheKey, data: res.data, expiresIn: 60});
      handleRes(res.data);
    });
  },

  search(context, params) {
    const handleRes = (resData) => {
      let products = resData.data;
      products.data.forEach((product) => {
        product.competitors = null;
      });
      context.commit('saveSearchResults', {
        products: products,
        departments_hierarchy: resData.departments_hierarchy,
        departments: resData.departments,
        brands: resData.brands.map(e => { e.brand_name = e.brand_name.toLowerCase(); return e; }),
        priceRanges: resData.price_ranges,
        otherFacets: resData.other_facets,
        removedInStockOnly: resData.removed_instock_only
      });
    };

    // WHAT THE FUCK IS GOING ON HERE?????????????????
    // IS dept_id GETTING SET FUCKING AFTER THIS FUNCTION IS CALLED? HOLY FUCKING SHIT
    // FUCK THIS ENTIRE FUCKING PROJECT
    //params = JSON.parse(JSON.stringify(params));
    const cacheKey = "search:" + JSON.stringify(params);
    const cachedValue = context.getters.getCachedValue(cacheKey);
    //console.log('cacheKey=', cacheKey);
    //console.log('cachedValue=', cachedValue);
    if ( cachedValue ) {
      handleRes(cachedValue);
      return Promise.resolve(cachedValue);
    }
    return SearchApiService.searchResults(params).then((res) => {
      context.commit('setCacheValue', {key: cacheKey, data: res.data, expiresIn: 120});
      handleRes(res.data);
    });
  },

  rentals(context) {
    return RentalApiService.getRentalProducts().then(res => {
      context.commit('saveRentals', res.data);
    });
  },

  departments(context) {
    return DepartmentsService.getDepartments().then(res => {
      context.commit('saveDepartmentResults', res.data.data);
    });
  },

  clearSearch(context) {
    context.state.searchResults = null;
  },

  logout(context) {
    context.state.activeUser = null;
  },

  setPreferences(context) {
    HomePageApiService.getPreferences()
      .then(data => {
        context.state.preferences = data;
      });
  },

  storeLocations(context, stores) {
    context.state.storeLocations = stores;
  },
  customPages(context, pages) {
      context.state.customPages = pages;
  },
  footer(context, footer) {
      context.state.footer = JSON.parse(footer);
  },
  customNavigationGroups(context, nav) {
      context.state.customNavigationGroups = nav;
  },

  customNavigation(context, nav) {
      context.state.customNavigation = nav;
  },
  trackUser({ state }) {
    const activeUser = state.activeUser;
    if(activeUser && activeUser.is_admin) {
      return;
    }
    if (!localStorage.userTracked) {
      HomePageApiService.trackUserDetails().then((data) => {
        if(data.data.res == true) {
          localStorage.setItem('userTracked', data.data.res);
        }
      });
    }
  },
  widgets({commit}) {
    return WidgetsApiService.get().then(res => {
      commit('saveWidgets', res.data.widgets);
    });
  },
  widgetTypes({commit}) {
    return WidgetsApiService.getAllTypes().then(res => {
      commit('saveWidgetTypes', res.data.widget_types);
    });
  },
  async fetchShippingQuotes(context) {
    if (context.state.activeShippingCarriers.length == 0 || context.state.cartItemsRestricted || !context.state.cartShippingAddressValidated) {
      return;
    }
    context.state.cartFetchingShippingQuotes = true;
    await CartApiService.shippingQuotes({customerAddress: context.state.cartShippingAddress}).then(response => {
      response = response.data;
      if (response.status === 'success') {
        context.state.cartShippingQuotes = response.quotes;
        if (response.quotes.length) {
          if (context.state.cartSelectedShippingQuote) {
            let matched = false;
            context.state.cartShippingQuotes.forEach(quote => {
              if (quote.carrier == context.state.cartSelectedShippingQuote.carrier && quote.service == context.state.cartSelectedShippingQuote.service) {
                context.commit('setCartSelectedShippingQuote', quote);
                matched = true;
              }
            });
            setTimeout(() => {
              if (!matched) {
                context.commit('setCartSelectedShippingQuote', null);
              }
            }, 200);
          }
        }
        return Promise.resolve();
      }
    }).finally(() => {
      context.state.cartFetchingShippingQuotes = false;
    });
  }
};
