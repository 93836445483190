<template>
  <section class="testimonials widget" :class="{'editable': editable}">
    <div class="d-flex justify-content-between align-items-center" v-if="editable">
      <div class="title flex-grow-1 d-flex align-items-start mb-3">
        <div>
          <h4 class="mb-0">Testimonials</h4>
          <div v-if="editable" :class="`small ${hidden ? 'text-danger' : 'text-success'}`">This widget is {{ hidden ? 'hidden' : 'visible'}}</div>
        </div>
        <div class="edit-buttons ml-3">
          <a href="#" class="remove-bt" @click.prevent="remove">
            <svg width="14" height="16" xmlns="http://www.w3.org/2000/svg"><g transform="translate(-8 -7)" fill-rule="nonzero" fill="none"><circle fill="#E2E2E3" cx="15" cy="15" r="15"/><path d="M21 13v8c0 1.1-.9 2-2 2h-8c-1.1 0-2-.9-2-2v-8h12zm-3-6c.6 0 1 .4 1 1v2h3v2H8v-2h3V8c0-.6.4-1 1-1zm-1 2h-4v1h4V9z" fill="#90939A"/></g></svg>
          </a>
        </div>
      </div>
      <div v-if="editable && master">
        Added by EZ-AD Representative
      </div>
    </div>
    <div class="d-flex align-items-center justify-between w-100 title-bar mb-4" v-if="title || editable">
      <div class="flex-grow-1 d-flex align-items-center">
        <input v-if="editable" class="form-control mr-5" placeholder="Title for Testimonials (optional)" v-model="intTitle" />
        <h4 class="mb-0" v-else-if="title" v-html="title" />
      </div>
      <button v-if="editable" @click="addItem" class="btn btn-outline-primary btn-sm d-flex align-items-center">
        Add Testimonial
      </button>
      <div class="custom-control custom-switch ml-4" v-if="editable">
        <input type="checkbox" v-model="intHidden" class="custom-control-input" :id="`hidden${id}`" @change="updateHidden">
        <label class="custom-control-label" :for="`hidden${id}`">Hide</label>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div v-if="!testimonials.length" class="no-testimonials cursor-pointer" @click="addItem">
          Please, add some testimonials to activate the widget
        </div>
        <swiper :ref="`testimonialsSlider-${id}`" :options="swiperOptions" class="slider pb-4 px-5 px-md-0 h-100">
          <swiper-slide v-for="(testimonial, key) in testimonials" :key="'testimonial-' + key" class="h-100">
            <testimonial-item :item="testimonial" @remove="removeItem" :editable="editable" @onChange="onChange" />
          </swiper-slide>
        </swiper>
        <div :class="`swiper-button-prev testimonial-swiper-${id}-prev ml-4 ml-md-0`" slot="button-prev" />
        <div :class="`swiper-button-next testimonial-swiper-${id}-next mr-4 mr-md-0`" slot="button-next" />
      </div>
    </div>
  </section>
</template>

<script>
import { debounce } from 'debounce';

export default {
  name: 'Testimonials',
  props: {
    title: {
      default: ''
    },
    editable: {
      default: false
    },
    testimonials: {
      default: null
    },
    id: {
      default: null
    },
    master: {
      default: false
    },
    hidden: {
      default: true
    }
  },
  data() {
    return {
      intTitle: '',
      swiperOptions: {
        spaceBetween: 10,
        slidesPerView: 3,
        centeredSlides: false,
        allowTouchMove: false,
        navigation: {
          nextEl: `.testimonial-swiper-${this.id}-next`,
          prevEl: `.testimonial-swiper-${this.id}-prev`
        },
        breakpoints: {
          1023: {
            slidesPerView: 2,
          },
          767: {
            slidesPerView: 1,
            spaceBetween: 30
          },
        }
      },
      intHidden: true
    };
  },
  created() {
    this.intTitle = this.title;
    this.intHidden = this.hidden;
  },
  computed: {
    swiper() {
      return this.$refs[`testimonialsSlider-${this.id}`].swiper;
    }
  },
  methods: {
    remove() {
      this.$emit('remove');
    },
    addItem() {
      this.$emit('addItem');
    },
    removeItem(item) {
      this.$emit('removeItem', item);
    },
    onChange(item) {
      this.$emit('onChange', item);
    },
    updateHidden() {
      this.$emit('updateHidden', this.intHidden);
    }
  },
  watch: {
    intTitle: debounce(function(value) {
      this.$emit('updateTitle', value);
    }, 1500)
  }
};
</script>

<style lang="scss">
  .testimonials {
    h4 {
      margin-bottom: 16px;
    }
    .slider {
      margin-top: 15px;
      min-height: 150px;
      .testimonial {
        padding: 24px;
        background: white;
        border-radius: 13px;
        box-shadow: 0 14px 10px 0 rgba(34, 44, 73, 0.04);
        .remove-bt {
          background: #E2E2E3;
          width: 30px;
          height: 30px;
          border-radius: 30px;
          transform: scale(0.85);
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: 50%;
          margin-left: -15px;
          top: -15px;
        }
        .message {
          font-size: 18px;
          line-height: 1.4;
          font-weight: normal;
          margin-bottom: 18px;
        }
      }
    }

    &.editable {
      .slider {
        padding: 15px 15px 0 5px;
      }
      .add-bt svg * {
        stroke: var(--primary);
      }
      .add-bt {
        top: 20px;
        width: 50px;
        height: calc(100% - 44px);
        position: absolute;
        right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      [contenteditable] {
        border: 1px dashed #dadada;
      }
      [contenteditable]:focus {
        border: 1px dashed var(--primary) !important;
        outline: none;
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      position: absolute;
      width: 24px;
    }

    .swiper-button-next {
      right: -16px;
    }

    .swiper-button-prev {
      left: -16px;
    }
  }
  .no-testimonials {
    border: 1px dashed #ef8c8c;
    background: #fff6f6;
    z-index: 2;
    height: calc(100% - 36px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1023px) {
    .testimonials {
      .slider {
        .testimonial {
          opacity: 0.6;
        }

        .swiper-slide-active {
          .testimonial {
            opacity: 1;
          }
        }
      }
    }
  }
</style>