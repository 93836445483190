<template>
  <div>
    <h6 class="card-title font-weight-bold">{{userInfo && 'Update Contact Info' || 'Create Account To Continue'}}</h6>
    <div class="row">
      <div class="col-sm-6 col-lg-6 mb-3">
        <label>First Name</label>
        <div v-if="memberInfo && loggedInUser && memberInfo.first_name && !editFirst">
          {{ memberInfo.first_name }}
          <i @click="editFirst = true" style="color: #4285F4;cursor: pointer" class="fa fa-pencil ml-2"></i>
        </div>
        <input v-else :disabled="isDisabled" @change="clearError('first_name')" placeholder="First Name" v-model="memberInfo.first_name" type="text" class="form-control p-2" :class="{'is-invalid':errors.first_name}">
        <div v-if="errors.first_name" class="invalid-feedback">
          {{errors['first_name'][0] | parseResponse}}
        </div>
      </div>
      <div class="col-sm-6 col-lg-6 mb-3">
        <label>Last Name</label>
        <div v-if="memberInfo && loggedInUser && memberInfo.last_name && !editLast">
          {{ memberInfo.last_name }}
          <i @click="editLast = true" style="color: #4285F4;cursor: pointer" class="fa fa-pencil ml-2"></i>
        </div>
        <input v-else :disabled="isDisabled"  @change="clearError('last_name')" v-model="memberInfo.last_name" type="text" placeholder="Last Name" class="form-control p-2"
        :class="{'is-invalid':errors.last_name}">
        <div v-if="errors.last_name" class="invalid-feedback">
          {{errors.last_name[0] | parseResponse}}
        </div>
      </div>
      <div class="col-sm-6 col-lg-6 mb-3">
        <label>Phone Number</label>

        <div v-if="memberInfo && loggedInUser && memberInfo.telephone && !editTelephone">
          {{ memberInfo.telephone }}
          <i @click="editTelephone = true" style="color: #4285F4;cursor: pointer" class="fa fa-pencil ml-2"></i>
        </div>
        <input v-else :disabled="isDisabled"  @change="clearError('telephone')" v-model="memberInfo.telephone" type="tel" placeholder="Phone Number" class="form-control p-2"
        :class="{'is-invalid':errors.telephone}">
        <div v-if="errors.telephone" class="invalid-feedback">
          {{errors.telephone[0] | parseResponse}}
        </div>
      </div>
      <div class="col-sm-6 col-lg-6 mb-3">
        <label>Email</label>
        <div v-if="memberInfo && loggedInUser && memberInfo.email && !editEmail">
          {{ memberInfo.email }}
          <i @click="editEmail = true" style="color: #4285F4;cursor: pointer" class="fa fa-pencil ml-2"></i>
        </div>
        <input v-else :disabled="isDisabled"  @change="clearError('email')" v-model="memberInfo.email" type="email" placeholder="Email" class="form-control p-2" :class="{'is-invalid':errors.email}">
        <div v-if="errors.email" class="invalid-feedback">
          {{errors.email[0] | parseResponse}}
        </div>
      </div>
      <div class="col-6 mb-3 d-flex flex-column" v-if="userInfo && $store.state.businessDetails.sms_notifications_enabled">
        <label><strong>SMS Updates</strong></label>
        <div class="flex-grow-1 d-flex align-items-end">
          <div class="custom-control custom-switch">
            <input type="checkbox" v-model="memberInfo.receive_sms" class="custom-control-input" id="receive-sms">
            <label
              class="custom-control-label"
              for="receive-sms"
              v-html="memberInfo.receive_sms ? 'Enabled' : 'Disabled'">
            </label>
          </div>
        </div>
      </div>
      <!-- 5/20 removing for now per truevalue spreadsheet -->
      <div class="col-6 mb-3" v-if="userInfo && !isTruevalue">
        <label><strong>Receive Marketing Emails</strong><br>Don't miss our great deals!</label>
        <div class="custom-control custom-switch">
          <input type="checkbox" v-model="memberInfo.receive_marketing" class="custom-control-input" id="receive-marketing" @change="enableMarketingEmails">
          <label class="custom-control-label" for="receive-marketing" v-html="memberInfo.receive_marketing ? 'Enabled' : 'Disabled'"></label>
        </div>
      </div>
    </div>
    <template v-if="!userInfo">
      <div class="row">
        <div class="col-sm-6 mb-3">
          <label>Password</label>
          <input :disabled="isDisabled" v-model="memberInfo.password" ref="password" type="password" placeholder="Password" class="form-control p-2" :class="{'is-invalid':errors.password}">
          <div v-if="errors.password" class="invalid-feedback">
            {{errors.password[0] | parseResponse}}
          </div>
        </div>
        <div class="col-sm-6 mb-3">
          <label>Confirm Password</label>
          <input v-model="memberInfo.confirmPassword" :disabled="isDisabled" ref="c_password" type="password" placeholder="Confirm Password" class="form-control p-2" :class="{'is-invalid': errors.c_password}">
          <div v-if="errors.c_password" class="invalid-feedback">
            {{errors.c_password[0] | parseResponse}}
          </div>
        </div>
        <div class="col-sm-6 col-lg-12 mb-3" v-if="accountField.display">
          <label for="account_number">{{ accountField.label }} (optional)</label>
          <input
            v-model="memberInfo.account_number"
            id="account_number"
            type="text"
            class="form-control">
          <small class="form-text text-muted">{{ accountField.help }}</small>
        </div>

          <template v-if="$store.state.businessDetails.custom_fields && $store.state.businessDetails.custom_fields.signup ">
              <custom-input-fields
                      :customFields="$store.state.businessDetails.custom_fields.signup"
                      :customInfo.sync="memberInfo.custom_info"
                      outerClass="w-100"
                      innerClass="col-sm-6 col-lg-12 mb-3"/>
          </template>

      </div>
      <div class="success-msg mt-3" v-if="registerSuccess">
        <img src="/images/check.png" class="img-fluid mr-2" />Success! Your account has been created. You can now enter your Member Contact Info.
      </div>
      <b-alert
        class="mt-2"
        :show="alert.show"
        :variant="alert.variant"
        dismissible
        @dismissed="alert.show = false">
        {{ alert.message }}
      </b-alert>
      <div class="d-flex flex-column align-items-center mt-3">
        <button :disabled="isDisabled || loading" @click="createAccount"
                class="btn btn-primary w-100" v-if="!userInfo" type="button">
          Create Account <span v-if="loading" class="spinner-border spinner-border-sm ml-2"></span>
        </button>
        <div class="small text-center mt-3">
          Already have an account? <router-link class="ml-2" to="/login">Sign In</router-link>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import UserApiService from '../../api-services/user.service';
  import AuthApiService from '@/api-services/auth.service';
  import AuthController from '@/controllers/auth.controller';

  export default {
    name: 'cartMember',
    data() {
      return {
        loading: false,
        displayNewAccountSection: false,
        registerSuccess: false,
        editTelephone: false,
        editEmail:false,
        editLast: false,
        editFirst: false,
        memberInfo: {
          first_name: '',
          last_name: '',
          telephone: '',
          email: '',
          password: '',
          confirmPassword: '',
          account_number: '',
          receive_sms: true,
          receive_marketing: null,
          custom_info: {},

        },
        alert: {
          show: false,
          variant: 'success',
          message: 'Successfully registered! Please login.'
        },
        userInfo: AuthController.checkAuthStatus(),
        errors: {}
      };
    },
    props: {
      isDisabled: {
        type: Boolean,
        default: false
      },
      loggedInUser: null
    },
    filters: {
      parseResponse(val) {
        return val.replace('member.', '');
      }
    },
    mounted() {
      if(this.userInfo) {
        this.memberInfo.first_name = this.userInfo.data.customer.first_name;
        this.memberInfo.last_name = this.userInfo.data.customer.last_name;
        this.memberInfo.telephone = this.userInfo.data.customer.telephone;
        this.memberInfo.email = this.userInfo.data.customer.email;
        this.memberInfo.receive_sms = this.userInfo.data.customer.receive_sms;
        this.memberInfo.receive_marketing = this.userInfo.data.customer.receive_marketing == 0 ? 0 : 1;
      }
    },
    watch: {
      memberInfo: {
        deep: true,
        handler(value) {
          this.$emit('memberInfoChanged', value);
        }
      }
    },
    computed: {
      isTruevalue() {
        return this.$root.$children[0].isTruevalue;
      },
      accountField() {
        const infoJson = this.$store.state.businessDetails.account_field_info;
        if(!infoJson) {
          return { display: false };
        }
        const info = JSON.parse(infoJson);
        info.label = info.label || 'Contractor Account Number';
        info.help = info.help || 'Enter your contractor account number if you have a registered account with this business.';
        return info;
      }
    },
    methods: {
      clearError(index){
        this.errors[index] = null;
      },
      async createAccount() {
        if(this.memberInfo.confirmPassword !== this.memberInfo.password) {
          this.errors = {"c_password":["Password doesn't match"]};
          return;
        }
        this.memberInfo.telephone = this.memberInfo.telephone.replace(/[^0-9]/g, '');
        this.memberInfo.device_id = this.$store.state.device_id;
        this.loading = true;
        UserApiService.register(this.memberInfo).then(res => {
            if(!res || res.data.status === 'error') {
              this.loading = false;
              this.alert.show = true;
              this.alert.variant = 'danger';
              if(res.data.message) {
                this.alert.message = res.data.message;
              } else {
                this.alert.message = 'Error occurred during sign up. Please check the fields again';
              }
            } else if(res.data.status === 'success') {
              AuthApiService.login({email: this.memberInfo.email}, {password: this.memberInfo.password}, this.$store.state.device_id).then(response => {
                  this.loading = false;
                  if(response.data.status == "success") {
                    let data = response.data;
                    data.access_token = response.data.access_token;
                    this.$swal({
                      toast: true,
                      position: 'top',
                      showConfirmButton: false,
                      timer: 3000,
                      type: 'success',
                      title: 'Thank you for registering!'
                    });
                    AuthController.login(data);
                    this.userInfo = AuthController.checkAuthStatus();
                    this.$emit('loggedIn', this.userInfo);
                  }
                });
            }
          }, err => {
            this.loading = false;
            this.showErrors(err.response.data.errors);
          });
      },
      clearFields() {
        this.memberInfo = {
            first_name: '',
            last_name: '',
            telephone: '',
            email: '',
            password: '',
            account_number: '',
            receive_sms: false,
            receive_marketing: false
        };
      },
      showErrors(fields) {
        this.$swal(fields[Object.keys(fields)[0]][0], '', 'error');
        this.errors = fields;
      },
      async enableMarketingEmails() {
        var data = {
          receive_marketing: this.memberInfo.receive_marketing == true ? 1 : 0
        };
        var user = this.$store.state.activeUser;
        user.data.customer.receive_marketing = this.memberInfo.receive_marketing;
        this.$store.commit('setActiveUser', user);
        await UserApiService.setMarketingEmails(data);
      }
    }
  };

</script>

<style scoped lang="scss">
  :deep(label) {
    font-size: 13px !important;
  }
</style>
