<template>
  <div>
    <div class="sticky-top">
      <main-header ref="mainHeader" @toggleMobileMenu="toggleMobileMenu" :showMobileMenu="showMobileMenu" id="mainHeader"/>
      <main-nav @toggleMobileMenu="toggleMobileMenu" :showMobileMenu="showMobileMenu" ref="mainNav" />
    </div>
    <slot/>
    <main-footer :business="business" v-if="$store.state.settings.businessName !== 'Gerretsen Building Supply'" />
  </div>
</template>

<script>

export default {
  name: "DefaultLayout",
  data() {
    return {
      showMobileMenu: false
    };
  },
  computed: {
    business() {
      return this.$store.state.businessDetails;
    }
  },
  methods: {
    toggleMobileMenu() {
      this.showMobileMenu = !this.showMobileMenu;
      document.body.classList.toggle('overflow-hidden');
    },
    hideMobileMenu() {
      this.showMobileMenu = false;
      document.body.classList.remove('overflow-hidden');
    }
  },
  watch: {
    '$route'() {
      this.hideMobileMenu();
    }
  }
};
</script>
