<template>
    <li class="list-group-item">
        <div class="d-flex align-items-center">
            <button @click="toggleFeaturedProduct()" class="featured" :class="{ 'active': product.is_favourite }">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><title>Add as Featured</title><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><polygon points="12 2.489 15.09 8.751 22 9.755 17 14.629 18.18 21.511 12 18.261 5.82 21.511 7 14.629 2 9.755 8.91 8.751 12 2.489" fill="#ffffff" stroke="#a0a0ba" stroke-miterlimit="10"></polygon></g></svg>
            </button>
            <div class="thumb mr-3">
                <img :src="product.image_url" :alt="product.title" />
            </div>
            <div class="pr-2">
                <div class="name">{{ product.title | capitalize }}</div>
                <div class="code">{{ product.sku }}</div>
            </div>
            <div v-click-outside="hideOptions" class="options">
                <button @click="showOptions = !showOptions">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <g fill="#a0a0ba">
                            <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
                        </g>
                    </svg>
                </button>

                <div v-if="showOptions" class="menu">
                    <router-link to="/">Add as Featured</router-link>
                    <router-link :to="getProductUrl(product)">View Product Page</router-link>
                    <router-link :to="`/admin/settings/product-editor?type=sku&code=${product.sku}`">Edit Product</router-link>
                </div>
            </div>
        </div>
    </li>
</template>

<script>
    import AdminApiService from '@/api-services/admin.service';

    export default {
        name: 'AdminProductItem',
        props: {
            product: {
                type: Object,
                default: null
            },
        },
        data() {
            return {
                showOptions: false
            };
        },
        methods: {
            getProductUrl(i) {
                return `/products/${i.sku}/${this.$ezSlugify(i.title)}`;
            },
            hideOptions() {
                this.showOptions = false;
            },
            async toggleFeaturedProduct() {
                if (this.product.is_favourite) {
                    await this.removeFeaturedProduct();
                } else {
                    await this.addFeaturedProduct();
                }
            },
            async addFeaturedProduct() {
                await AdminApiService.addFavouriteProduct(this.product.upc).then(() => {
                    this.$emit('toggleProductFavourite', { upc: this.product.upc, is_favourite: true });
                });
            },
            async removeFeaturedProduct() {
                await AdminApiService.removeFavouriteProduct(this.product.upc).then(() => {
                    this.$emit('toggleProductFavourite', { upc: this.product.upc, is_favourite: false });
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    .code {
      font-size: 13px;
      color: #7e8695;
    }

    .name {
        font-size: 14px;
    }

    .featured {
        margin-right: 8px;
        border: none;
        background: none;
        
        svg {
            polygon {
                transition: 200ms;
            }
        }

        &:hover {
            &:not(.active) {
                fill-opacity: 0.5
            }
        }
        
        &:hover,
        &:active,
        &.active {
            svg {
                polygon {
                    transition: 200ms;
                    fill: var(--primary);
                    stroke: var(--primary);
                }
            }
        }
    }

    .thumb {
        padding: 2px;
        border: 1px solid #E8E8E8;
        border-radius: 4px;
        width: 50px;
        height: 50px;
        min-width: 50px;
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
        max-width: calc(100% - 4px);
        max-height: calc(100% - 4px);
        text-indent: -9999999px;
        overflow: hidden;
        }
    }

    .options {
        display: flex;
        justify-content: flex-end;
        flex: 1;
        position: relative;

        button {
            border: none;
            background: none;
            padding: 4px;
            border-radius: 50%;
            transition: 200ms;

            &:hover {
                background: #ececef;
            }
        }

        .menu {
            background: white;
            box-shadow: 0 14px 10px 0 rgba(34,44,73,0.05);
            border-radius: 8px;
            position: absolute;
            top: -36px;
            right: 30px;
            width: 180px;
            padding: 8px 0;
            z-index: 5;

            a {
                display: block;
                padding: 4px 16px;
                font-size: 13px;
                font-weight: bold;
            }
        }
    }
</style>