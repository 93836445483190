<template>
  <div class="position-relative swiper widget" :class="{'editable': editable}">
    <div class="d-flex justify-content-between align-items-center" v-if="editable">
      <div class="title flex-grow-1 d-flex align-items-start mb-3">
        <div>
          <h4 class="mb-0">Product Swiper</h4>
          <div v-if="editable" :class="`small ${hidden ? 'text-danger' : 'text-success'}`">This widget is {{ hidden ? 'hidden' : 'visible'}}</div>
        </div>
        <div class="edit-buttons ml-3">
          <a href="#" class="remove-bt" @click.prevent="remove">
            <svg width="14" height="16" xmlns="http://www.w3.org/2000/svg"><g transform="translate(-8 -7)" fill-rule="nonzero" fill="none"><circle fill="#E2E2E3" cx="15" cy="15" r="15"/><path d="M21 13v8c0 1.1-.9 2-2 2h-8c-1.1 0-2-.9-2-2v-8h12zm-3-6c.6 0 1 .4 1 1v2h3v2H8v-2h3V8c0-.6.4-1 1-1zm-1 2h-4v1h4V9z" fill="#90939A"/></g></svg>
          </a>
        </div>
      </div>
      <div v-if="editable && master">
        Added by EZ-AD Representative
      </div>
    </div>
    <div v-if="title || editable" class="d-flex align-items-center justify-between w-100 title-bar mb-3">
      <div class="flex-grow-1 d-flex align-items-center justify-content-center justify-content-sm-start text-center text-sm-left">
        <input v-if="editable" class="form-control mr-5" placeholder="Title for the swiper (optional)" v-model="intTitle" />
        <h4 class="mb-0" v-else-if="title" v-html="title" />
      </div>
      <search-suggestions
        departmentsLabel="Departments (Random)"
        brandsLabel="Brands (Random)"
        ref="search"
        placeholder="Add a product, department or brand"
        @onSelected="onAdd" class="search-wrapper"
        v-if="editable" />
      <div class="custom-control custom-switch ml-4" v-if="editable">
        <input type="checkbox" v-model="intLoop" class="custom-control-input" :id="`is-loop${id}`" @change="updateLoop">
        <label class="custom-control-label" :for="`is-loop${id}`">Loop</label>
      </div>
      <div class="custom-control custom-switch ml-4" v-if="editable">
        <input type="checkbox" v-model="intHidden" class="custom-control-input" :id="`hidden${id}`" @change="updateHidden">
        <label class="custom-control-label" :for="`hidden${id}`">Hide</label>
      </div>
    </div>
    <div v-if="!loading && editable && (!filteredProducts || (filteredProducts && !filteredProducts.length))" class="no-products">
      Please, add some products to activate the swiper
    </div>
    <!--<div v-if="selectedDepartment && editable" class="d-flex align-items-center mb-1">
      <select class="form-control form-control-sm mr-2 w-auto" v-model="randomProductsAmount">
        <option :val="e" v-for="e in [5,10,15,20,25]" :key="`random-${e}`">{{e}}</option>
      </select> random products in <b class="ml-1">{{ selectedDepartment.text }}</b>
    </div>-->
    <div v-if="selectedDepartment && editable" class="mb-1">
      Showing <b>{{this.randomProductsAmount}}</b> random products in <b>{{ selectedDepartment.text }}</b>
    </div>
    <div v-if="selectedBrand && editable" class="mb-1">
      Showing <b>{{this.randomProductsAmount}}</b> random products in <b>{{ selectedBrand.text }}</b>
    </div>
    <div v-if="loading" class="loading-wrapper">
      <div class="spinner-border"></div>
    </div>
    <div class="position-relative swiper-container" @mouseenter="pauseLoop" @mouseleave="doLoop">
      <draggable :ref="`pDraggable-${id}`" class="row small-gutters" :list="swiperProducts" :group="`productSwiperGroup-${id}`" :disabled="!editable || departmentRandomArray.length" @change="onReorder">
        <div :class="`col-6 col-sm-${12 / visibleItems} mb-2 swiper-slide`" v-for="(item, index) in swiperProducts" :key="`productswiper-${item.title}${index}`">
          <product-item :target="target" :class="{'draggable-item' : editable && !departmentRandomArray.length && !brandRandomArray.length}" @remove="removeItem" :editable="editable && !departmentRandomArray.length && !brandRandomArray.length" :item="item" :internal-api="internalApi" :add-start-stop-scroller="true" :show-add-cart="showAddCart" />
        </div>
      </draggable>
      <template v-if="visibleItems < filteredProducts.length">
        <div :ref="`product-swiper-${id}-prev`" class="swiper-button-prev" @click="prevPage" v-if="showPrevPage" />
        <div :ref="`product-swiper-${id}-next`" class="swiper-button-next" @click="nextPage" v-if="showNextPage" />
      </template>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import searchSuggestions from '@/components/search-suggestions';
import ProductApiService from '@/api-services/product.service';
import { debounce } from 'debounce';

export default {
  name: 'ProductSwiper',
  props: {
    id: {
      default: null
    },
    products: {
      default: null
    },
    internalApi: {
      default: false
    },
    showAddCart: {
      default: true
    },
    editable: {
      default: false
    },
    title: {
      default: ''
    },
    itemsToShow: {
      default: null
    },
    loop: {
      default: true
    },
    autoPlay: {
      default: null
    },
    master: {
      default: false
    },
    hidden: {
      default: true
    },
    target: {
      default: '_self'
    }
  },
  components: {
    draggable,
    searchSuggestions
  },
  data() {
    return {
      testList: [],
      intTitle: '',
      intLoop: true,
      intHidden: true,
      loading: false,
      departmentRandomArray: [],
      brandRandomArray: [],
      selectedDepartment: null,
      selectedBrand: null,
      randomProductsAmount: 10,
      windowWidth: window.innerWidth,
      currentPage: 1,
      oneByOne: this.loop,
      loopInterval: null
    };
  },
  created() {
    this.intTitle = this.title;
    this.intHidden = this.hidden;
    this.intLoop = this.loop;
    window.addEventListener('resize', () => this.windowWidth = window.innerWidth);
  },
  computed: {
    showPrevPage() {
      if((this.loop && !this.editable) || this.currentPage > 1) {
        return true;
      }
      return false;
    },
    showNextPage() {
      if((this.loop && !this.editable) || !this.swiperProducts.some(e => e.id === this.filteredProducts[this.filteredProducts.length - 1].id)) {
        return true;
      }
      return false;
    },
    lastPage() {
      return Math.ceil(this.products.length / this.visibleItems);
    },
    visibleItems() {
      return this.itemsToShow ? this.itemsToShow
           : this.windowWidth > 1200 ? 6
           : this.windowWidth > 992  ? 4
           : this.windowWidth > 768  ? 3
           : this.windowWidth > 577  ? 2
           :                           2;
    },
    departmentList() {
      if(this.$store.state.departmentResults && this.$store.state.departmentResults.parent_departments) {
        let ret = this.$store.state.departmentResults.parent_departments;

        return ret.map(e => {
          const { name: text, dept_id: value } = {...e};
          return Object.assign({}, { text, value });
        });
      }
      return [];
    },
    brandList() {
      if(this.$store.state.brands) {
        let ret = this.$store.state.brands.data;
        return ret.map(e => {
          const { brand_name: text, brand_id: value } = {...e};
          return Object.assign({}, { text, value });
        });
      }
      return [];
    },
    filteredProducts: {
      get() {
        let ret = [];

        if(!Array.isArray(this.products)) {
          if(this.products.dept_id) {
            ret = this.departmentRandomArray;
          } else if(this.products.brand_id) {
            ret = this.brandRandomArray;
          }
        } else {
          this.checkDepartments(null);
          ret = [...this.products];
        }

        return ret;
      },
      set(val) {
        return val;
      }
    },
    options() {
      return this.$store.state.homepage.find(x => x.widget_type === this.type);
    },
    swiperProducts() {
      let ret = [];
      let start, end = 0;
      if(!this.oneByOne) {
        start = (this.currentPage - 1) * this.visibleItems;
        end = this.currentPage * this.visibleItems;
      } else {
        start = this.currentPage - 1;
        end = this.visibleItems + this.currentPage - 1;
      }

      ret = [...this.filteredProducts].slice(start, end);

      if(this.loop && this.visibleItems < this.filteredProducts.length) {
        ret = ret.concat([...this.filteredProducts].slice(0, this.visibleItems - ret.length));
      }
      return ret;
    }
  },
  async mounted() {
    this.checkDepartments(this.products);
    this.doLoop();
  },
  methods: {
    async checkDepartments(val) {
      if(!val) {
        this.departmentRandomArray = [];
        this.brandRandomArray = [];
        this.selectedDepartment = null;
        return;
      }
      if(!Array.isArray(val)) {
        if(val.dept_id) {
          this.loading = true;
          let randomProducts = await ProductApiService.getRandomProducts({dept_id: val.dept_id, limit: this.randomProductsAmount});
          this.selectedDepartment = this.departmentList.find(e => e.value == val.dept_id);
          this.departmentRandomArray = randomProducts.data.data.data;
          this.loading = false;
        } else if(val.brand_id) {
          this.loading = true;
          let randomProducts = await ProductApiService.getRandomProducts({brand_id: val.brand_id, limit: this.randomProductsAmount});
          this.selectedBrand = this.brandList.find(e => e.value == val.brand_id);
          this.brandRandomArray = randomProducts.data.data.data;
          this.loading = false;
        }
      }
    },
    onReorder(e) {
      let movedElement = e.moved.element;

      if(!this.loop) {
        let oldIndex = e.moved.oldIndex;
        let newIndex = e.moved.newIndex;
        this.filteredProducts.splice(oldIndex + (this.currentPage - 1) * this.itemsToShow, 1);
        this.filteredProducts.splice(newIndex + (this.currentPage - 1) * this.itemsToShow, 0, movedElement);
      } else {
        let oldIndex = e.moved.oldIndex + this.currentPage - 1;
        let newIndex = e.moved.newIndex + this.currentPage - 1;
        this.filteredProducts.splice(oldIndex, 1);
        this.filteredProducts.splice(newIndex, 0, movedElement);
      }

      this.$emit('onReorder', this.filteredProducts);
    },
    onAdd(selected) {
      this.$emit('onSelected', selected);
    },
    removeItem(item) {
      this.$swal({
        title: 'Remove Product',
        html: `Are you sure you want to remove <div class="font-weight-bold text-capitalize">${item.title}</div> from the Product Swiper?`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.value) {
          this.$emit('removeItem', item);
          if(!this.loop && !this.swiperProducts.length) {
            this.currentPage = this.currentPage - 1;
          }
          if(this.loop && !this.showNextPage) {
            this.currentPage = this.currentPage - 1;
          }
          if(this.currentPage < 1) {
            this.currentPage = 1;
          }
        }
      });
    },
    remove() {
      this.$emit('remove');
    },
    prevPage() {
      this.currentPage = this.currentPage > 1 ? this.currentPage : this.filteredProducts.length + 1;
      this.currentPage = this.currentPage - 1;
      this.pauseLoop();
      this.doLoop();
    },
    nextPage() {
      let max = this.loop ? this.filteredProducts.length : Math.ceil(this.filteredProducts.length / this.visibleItems);
      this.currentPage = this.currentPage < max ? this.currentPage : 0;
      this.currentPage = this.currentPage + 1;
      this.pauseLoop();
      this.doLoop();
    },
    doLoop() {
      if(this.autoPlay && this.visibleItems < this.swiperProducts.length)
        this.loopInterval = setInterval(() => this.nextPage(), this.autoPlay);
    },
    pauseLoop() {
      this.autoPlay && clearInterval(this.loopInterval);
    },
    updateLoop() {
      this.currentPage = 1;
      this.oneByOne = this.intLoop;
      this.$emit('updateLoop', this.intLoop);
    },
    updateHidden() {
      this.$emit('updateHidden', this.intHidden);
    }
  },
  watch: {
    intTitle: debounce(function(value) {
      this.$emit('updateTitle', value);
    }, 1500),
    randomProductsAmount() {
      this.checkDepartments(this.products);
    },
    products(val) {
      this.checkDepartments(val);
    },
    visibleItems(val) {
      if(val >= this.filteredProducts.length) {
        this.currentPage = 1;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .swiper.widget {
    min-height: 280px;
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
  .swiper-container {
    overflow: visible;
  }
  .swiper-slide {
    animation: fadein .3s linear 1 normal forwards;
    opacity: 0;
    &:nth-child(2) {
      animation-delay: .05s;
    }
    &:nth-child(3) {
      animation-delay: .1s;
    }
    &:nth-child(4) {
      animation-delay: .15s;
    }
    &:nth-child(5) {
      animation-delay: .2s;
    }
    &:nth-child(6) {
      animation-delay: .25s;
    }
  }
  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
  .products-slider .swiper-wrapper {
    padding-bottom: 20px;
  }
  :deep(.draggable-item a.card) {
    cursor: move;
  }
  :deep(.search-wrapper) {
    width: 300px;
    input {
      width: 100%;
      font-size: 14px;
    }
    .autosuggest__results {
      width: 400px;
      right: 0;
      .autosuggest__results_item {
        font-size: 14px;
      }
    }
    .search-suggestion-footer {
      display: none;
    }
  }
  .widget-title {
    font-weight: bold;
  }
 .swiper-slide {
    width: auto !important;
    min-height: 332px;
height: auto;
  }
  .swiper-button-disabled {
    opacity: 0;
  }

  .no-products {
    border: 1px dashed #ef8c8c;
    background: #fff6f6;
    top: 20px;
    height: 250px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .loading-wrapper {
    top: 20px;
    height: 250px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 1500px) {
    .swiper-container {
      padding: 0 40px;
    }
  }
  @media (max-width: 575px) {
    .swiper-container {
      padding: 0 12px;
    }
    .widget-title {
      text-align: center;
    }
    .products-slider {
      margin: 0 -15px !important;
    }
    .swiper-slide {
      height: 280px;
      :deep(.card) {
        margin: 0 auto !important;
        border-radius: 0;
        .button-prev,
        .button-next {
          display: none;
        }
        :deep(.product) {
          border-right: 1px solid #f3f3f3;
          .badge.badge-danger {
            padding: 0;
            height: auto;
            background: none;
            color: #dc3545;
            letter-spacing: -.4px;
          }
          .small-text {
            font-size: 12px;
            text-transform: uppercase;
          }
          .add-to-cart {
            .q {
              min-width: 24px;
              height: 24px;
              svg {
                transform: scale(.9);
              }
            }
          }
          img.product-image {
            margin-bottom: 0;
            max-height: 110px;
          }
          h6 {
            font-size: 12px;
            margin-bottom: 36px;
          }
        }
      }
    }
  }
</style>

