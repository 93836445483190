<template>
  <div class="cart-totals">
    <div v-if="settings.cart.taxDisclaimer" v-html="settings.cart.taxDisclaimer" class="small" />

    <div class="text-right flex-grow-1 d-flex flex-column" v-if="cart && totalItems > 0">
      <div class="mt-2" v-if="loggedInUser && cart.coupons">Redeemed Coupons: <span class="n">{{ cart.coupons }}</span></div>
      <div class="mt-2">Subtotal: <span v-if="settings.products.currencyPrefix">{{settings.products.currencyPrefix}}</span><span class="n">${{ cart.subtotal | productPrice }}</span></div>
      <div class="mt-2" v-if="cart.additional_fee && cart.additional_fee != '0.00'">
        <div class="cursor-pointer font-weight-bold" @click="showAdditionalFees = !showAdditionalFees">
          Additional Fees <i :class="{'rotate': showAdditionalFees}" class="fa fa-angle-down mx-1 transition"></i>
          ${{ cart.additional_fee | productPrice }}
        </div>
        <template v-if="showAdditionalFees">
          <div v-for="(fee, i) in additionalFees" :key="`fee-${i}`" class="my-1">
            {{ fee.message }}: ${{ fee.price_impact | productPrice }}
          </div>
        </template>
      </div>
      <div class="mt-2" v-if="cart.special_order_fee && cart.special_order_fee != '0.00'"> {{ specialOrderFeeText }}: <span v-if="settings.products.currencyPrefix">{{settings.products.currencyPrefix}}</span><span class="n">${{ cart.special_order_fee | productPrice }}</span></div>
      <div class="mt-2" v-if="!settings.cart.hideTax && loggedInUser && cart.tax">Tax: <span v-if="settings.products.currencyPrefix">{{settings.products.currencyPrefix}}</span><span class="n">${{ cart.tax | productPrice }}</span></div>
      <template>
        <div v-for="(taxRow, taxRowI) in otherTaxRows" :key="`taxRow-${taxRowI}`" class="mt-2">
          {{ taxRow.label }}: <span v-if="settings.products.currencyPrefix">{{settings.products.currencyPrefix}}</span><span class="n">${{ taxRow.amount | productPrice }}</span>
        </div>
      </template>
      <div class="mt-2" v-if="typeName === 'shipping' && (activeShippingCarriers.length && shippingFee > 0 || !activeShippingCarriers.length && cart.shipping_fee > 0)">
        Shipping & Handling: <span v-if="settings.products.currencyPrefix">
        {{settings.products.currencyPrefix}}</span>
        <span class="n">
          ${{ shippingFee | productPrice }}
        </span>
      </div>
      <div class="mt-2" v-if="typeName === 'shipping' && cart.discount_shipping && cart.discount_shipping != 0.00">
        Shipping Discount: <span v-if="settings.products.currencyPrefix">{{settings.products.currencyPrefix}}</span><span class="n">${{ cart.discount_shipping | productPrice }}</span>
      </div>
      <div class="mt-2" v-if="typeName === 'delivery'"> <!-- removed && cart.delivery_fee check, wasn't showing this row -->
        <template v-if="!deliveryFee || deliveryFee.error">
          <a v-if="deliveryLocations && deliveryLocations.length" href="#" @click.prevent="$bvModal.show('delivery-locations-modal')">Delivery Charge:</a>
          <span v-else>Delivery Charge:</span>
          Add address to calculate
        </template>
        <div v-else>
          Delivery Charge: <span v-if="settings.products.currencyPrefix">{{settings.products.currencyPrefix}}</span>
          <span v-if="deliveryFee === 0" class="n">FREE</span>
          <span v-else class="n">${{ deliveryFee | productPrice }}</span>
        </div>
      </div>
      <div class="mt-2" v-if="typeName === 'delivery' && cart.discount_delivery && cart.discount_delivery != 0.00">
        Delivery Discount: <span v-if="settings.products.currencyPrefix">{{settings.products.currencyPrefix}}</span><span class="n">${{ cart.discount_delivery | productPrice }}</span>
      </div>
      <div class="mt-2" v-if="cart.discount && cart.discount != 0.00">{{ cart.discount_label || 'Discount' }}: <span class="n">${{ cart.discount | productPrice }}</span></div>
      <div class="mt-2 font-weight-bold total-price">
        Total:
        <span v-if="settings.products.currencyPrefix">{{settings.products.currencyPrefix}}</span>
        <span class="n">
          ${{totals | productPrice }}
        </span>      </div>
    </div>

    <b-modal hide-footer id="delivery-locations-modal" title="Delivery Details" v-if="deliveryLocations">
       <b-table-lite outlined :items="deliveryLocations.map(location => { return { deliveryZipCodes: location.code, charge: !location.fee ? 'FREE' : `$${location.fee}` } })"></b-table-lite>
    </b-modal>
  </div>
</template>

<script>
  import AuthController from '@/controllers/auth.controller';

  export default {
    name: 'cartTotals',
    props: ['typeName', 'zipcode', 'taxesCalculated'],
    data() {
      return {
        loggedInUser: AuthController.checkAuthStatus(),
        showAdditionalFees: false,
        useCustomShipping: false
      };
    },
    computed: {
      cart() {
        return this.$store.state.cart;
      },
      settings() {
        return this.$store.state.settings;
      },
      deliveryLocations() {
        return this.$store.state.businessDetails.delivery_locations;
      },
      deliveryFee() {
        if(this.settings.cart.hideDeliveryZipcode)
          return this.cart.delivery_fee;
        if(this.zipcode) {
          if(this.deliveryLocations.length) {
            let foundLoc = this.deliveryLocations.find(e => e.code == this.zipcode);
            if (foundLoc) {
              // if subtotal is >= threshold, use the threshold fee instead
              if ( foundLoc.minThreshold && foundLoc.thresholdFee ) {
                let subtotal = Number(this.cart.subtotal);
                if ( subtotal >= foundLoc.minThreshold ) {
                  return foundLoc.thresholdFee;
                }
              }
              return foundLoc.fee;
            } else {
              return { error: 'invalid-zipcode' };
            }
          } else {
            return this.cart.delivery_fee;
          }
        }
        return { error: 'no-zipcode' };
      },
      additionalFees() {
        return this.cart.additional_fee_details;
      },
      totals() {
        let tax = this.loggedInUser ? (this.taxesCalculated ? Number(this.cart.tax) : 0) : 0;
        let subtotal = Number(this.cart.subtotal) + Number(this.cart.additional_fee) - Number(this.cart.discount);
        if ( this.cart.special_order_fee && this.cart.special_order_fee !== '0.00' ) {
          subtotal += Number(this.cart.special_order_fee);
        }
        if(this.typeName === 'pickup') {
          return subtotal + tax;
        }
        if(this.typeName === 'shipping') {
          return subtotal + tax + this.shippingFee - Number(this.cart.discount_shipping);
        }
        if(this.typeName === 'delivery') {
          return subtotal + tax + (Number(this.deliveryFee) || 0) - Number(this.cart.discount_delivery);
        }
        return subtotal;
      },
      otherTaxRows() {
        return this.cart.otherTaxRows || [];
      },
      totalItems() {
        return this.$store.state.cartItemCount;
      },
      specialOrderFeeText() {
        return this.settings.text.specialOrderFee || 'Special Order';
      },
      activeShippingCarriers() {
        return this.$store.state.activeShippingCarriers;
      },
      shippingFee() {
        if (this.activeShippingCarriers.length) {
          return parseFloat(this.$store.state.cartSelectedShippingQuote ? this.$store.state.cartSelectedShippingQuote.handling_fee : 0);
        }
        return this.cart.shipping_fee;
      }
    }
  };

</script>

<style lang="scss" scoped>
  .total-price {
    font-size: 20px;
  }
</style>
