import Vue from 'vue';
import Router from 'vue-router';
import { routes } from './routes';

import BreadcrumbMonitor from './breadcrumb.middleware';
import {handleFakeGASession} from "@/plugins/analytics";

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: routes
});

// interceptor for auth system
router.beforeEach(async (to, from, next) => {
  BreadcrumbMonitor.capture(from, to);
  handleFakeGASession();
  return next();
});

export default router;
