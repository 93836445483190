<template>
  <div>
    <section class="htmleditor widget" :class="{'editable': editable}" v-if="editable">
      <div class="d-flex justify-content-between align-items-center">
        <div class="title flex-grow-1 d-flex align-items-start mb-3">
          <div>
            <h4 class="mb-0">Classic HTML Editor</h4>
            <div v-if="editable" :class="`small ${hidden ? 'text-danger' : 'text-success'}`">This widget is {{ hidden ? 'hidden' : 'visible'}}</div>
          </div>
          <div class="edit-buttons ml-3">
            <a href="#" class="remove-bt" @click.prevent="remove">
              <svg width="14" height="16" xmlns="http://www.w3.org/2000/svg"><g transform="translate(-8 -7)" fill-rule="nonzero" fill="none"><circle fill="#E2E2E3" cx="15" cy="15" r="15"/><path d="M21 13v8c0 1.1-.9 2-2 2h-8c-1.1 0-2-.9-2-2v-8h12zm-3-6c.6 0 1 .4 1 1v2h3v2H8v-2h3V8c0-.6.4-1 1-1zm-1 2h-4v1h4V9z" fill="#90939A"/></g></svg>
            </a>
          </div>
        </div>
        <div class="mr-4" style="display: flex;" v-if="editable || body">
          <div class="custom-control custom-switch" v-if="editable">
            <input type="checkbox" v-model="intHidden" class="custom-control-input" :id="`hidden${id}`" @change="updateHidden">
            <label class="custom-control-label" :for="`hidden${id}`">Hide</label>
          </div>
        </div>
        <div v-if="editable && master">
          Added by EZ-AD Representative
        </div>
      </div>
      <div class="mt-3">
        <html-editor v-model="intBody" @input="updateBody" />
      </div>
    </section>
    <div v-if="body && !editable" v-html="body"></div>
  </div>
</template>
<script>

export default {
  name: 'ClassicHtmlEditor',
  props: {
    id: {
      default: null
    },
    editable: {
      default: false
    },
    body: {
      default: ''
    },
    hidden: {
      default: true
    },
    master: {
      default: false
    },
    page: {
      default: []
    },
    keepBody: {
      default: false
    }
  },
  data() {
    return {
      loading: false,
      intHidden: true,
      intBody: '',
      intData: {}
    };
  },
  created() {
    this.intData = this.data;
    this.intBody = this.body;
    this.intHidden = this.hidden;
  },
  mounted() {
    if(this.keepBody = false) {
      if(this.page.body != '') {
        this.intBody = this.page.body;
        this.page.body = '';
      }
    }
  },
  methods: {
    remove() {
      this.$emit('remove');
    },
    onChange(data) {
      this.$emit('onChange', data);
    },
    updateHidden() {
      this.$emit('updateHidden', this.intHidden);
    },
    updateBody() {
      this.$emit('updateBody', this.intBody);
    },
  },
};
</script>
