<template>
  <div class="departments-wrapper h-100">
    <router-link v-if="!editable && !item.name.startsWith('https://') && !item.name.startsWith('http://')"
    :to="{name: 'department-products-slug', params: {slug: this.$ezSlugify(item.name) + '-' + item.dept_id}, query: {name: item.name, in_stock_only: inStockOnlyParam} }">
      <div class="card-body">
        <div class="departments">
          <div v-if="!loaded" class="d-flex loader-wrapper align-items-center justify-content-center">
            <img src="/icons/loader.gif" class="loader">
          </div>
          <img
            ref="departmentImageCustomer"
            width="64"
            height="64"
            :src="item.image_url"
            :alt="item.name | lowerCase"
            @load="loaded = true"
            :class="{ 'd-none': !loaded }"
            class="department-image img-fluid mt-1 text-capitalize"
            :style="item.svg_custom_color ? item.svg_custom_color : colorFilter"
          >
        </div>
        <h6 class="text-capitalize mt-2 text-center" style="width:120px; font-size: 0.90rem; color: #000;">{{ item.custom_tile_name ? item.custom_tile_name : item.name }}</h6>
      </div>
    </router-link>
    <a v-if="!editable && (item.name.startsWith('https://') || item.name.startsWith('http://'))" :href="item.name">
      <div class="card-body">
        <div class="departments">
          <div v-if="!loaded" class="d-flex loader-wrapper align-items-center justify-content-center">
            <img src="/icons/loader.gif" class="loader">
          </div>
          <img
            ref="departmentImageCustomer2"
            width="64"
            height="64"
            :src="item.image_url"
            :alt="item.name | lowerCase"
            @load="loaded = true"
            :class="{ 'd-none': !loaded }"
            class="department-image img-fluid mt-1 text-capitalize"
            :style="item.svg_custom_color ? item.svg_custom_color : colorFilter"
          >
        </div>
        <h6 class="text-capitalize mt-2 text-center" style="width:120px; font-size: 0.90rem; color: #000;">{{ item.custom_tile_name ? item.custom_tile_name : item.name }}</h6>
      </div>
    </a>
    <div class="card-body" v-if="editable">
      <div class="d-flex justify-content-around mb-3" style="width: 127px;">
        <b-button id="show-btn" style="margin-top: 2px;" class="btn-xs d-flex justify-content-around" @click="showModal(item)"><i class="fa fa-cog" aria-hidden="true"></i></b-button>
        <div class="edit-buttons">
          <a href="#" class="remove-bt" @click.stop.prevent="remove(item)">
            <svg width="14" height="16" xmlns="http://www.w3.org/2000/svg"><g transform="translate(-8 -7)" fill-rule="nonzero" fill="none"><circle fill="#E2E2E3" cx="15" cy="15" r="15"/><path d="M21 13v8c0 1.1-.9 2-2 2h-8c-1.1 0-2-.9-2-2v-8h12zm-3-6c.6 0 1 .4 1 1v2h3v2H8v-2h3V8c0-.6.4-1 1-1zm-1 2h-4v1h4V9z" fill="#90939A"/></g></svg>
          </a>
        </div>
      </div>
      <div class="departments">
        <div v-if="!loaded" class="d-flex loader-wrapper align-items-center justify-content-center">
          <img src="/icons/loader.gif" class="loader">
        </div>
        <img
          ref="departmentImageAdmin"
          width="64"
          height="64"
          :src="item.image_url"
          :alt="item.name ? item.name : 'Your image goes here'"
          @load="loaded = true"
          :class="{ 'd-none': !loaded }"
          class="department-image img-fluid mt-1"
          :style="item.svg_custom_color ? item.svg_custom_color : colorFilter"
        >
      </div>
      <h6 class="text-capitalize mt-2 text-center" style="width:115px; font-size: 0.90rem; color: #000;">{{ item.custom_tile_name ? item.custom_tile_name : item.name }}</h6>
      <b-modal ref="my-modal" size="xl" header-bg-variant="primary" hide-footer no-close-on-esc no-close-on-backdrop hide-header-close>
        <template #modal-title>
          <div class="text-capitalize text-white">Edit {{item.custom_tile_name ? item.custom_tile_name : item.name}} Tile</div>
        </template>
        <b-tabs v-model="tabIndex" content-class="mt-3" justified>
          <b-tab active>
            <template #title>
              <h6 class="text-muted font-weight-bold text-uppercase mt-1" style="font-size: 14px;">Choose an icon</h6>
            </template>
            <div class="row mb-1" style="margin-left: 1px;">
              <div class="container col-md-10 d-flex flex-wrap icons-container mb-3" style="overflow: overlay; height: 300px; max-height: 300px; border: 1px solid rgb(170, 170, 170); box-sizing: border-box; box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05); border-radius: 10px;">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/02/15/63ed248c53f6c_building-supplies.svg" alt="Building Supplies" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/02/16/63ee24362f8fa_paint.svg" alt="Paint" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/02/16/63ee247630384_electrical.svg" alt="Electrical" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/02/16/63ee250623fb6_lighting.svg" alt="Lighting" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/02/16/63ee253a8950d_tiles-flooring.svg" alt="Tiles Flooring" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/02/16/63ee256c9da3f_bathroom.svg" alt="Bathroom" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/02/16/63ee260c721f0_bath-faucet_1.svg" alt="Bath Faucet" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/02/16/63ee263700561_lumber.svg" alt="Lumber" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/02/16/63ee265d2ef7c_metal.svg" alt="Metal" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/02/16/63ee2900592c7_door.svg" alt="Door" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/02/16/63ee292e0bda6_hammer.svg" alt="Hammer" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/02/16/63ee295248228_drill.svg" alt="Drill" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/02/16/63ee29d31e407_houseware.svg" alt="Houseware" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/02/16/63ee2a221ed04_bedding.svg" alt="Bedding" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/02/16/63ee2a43cfb93_car.svg" alt="Car" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/03/16/64138ce91977f_hardware.svg" alt="Hardware" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/03/16/641390464715e_chainsaw.svg" alt="Chainsaw" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/02/16/63ee2a7119ef2_lawn.svg" alt="Lawn" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/02/16/63ee2a8adf370_seeds.svg" alt="Seeds" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/02/16/63ee2ab1bb18f_fertilizers.svg" alt="Fertilizers" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/02/16/63ee2acee3c2d_pesticides.svg" alt="Pesticides" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/02/16/63ee2af408502_pets.svg" alt="Pets" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/02/16/63ee2b2994e48_farm.svg" alt="Farm" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/02/16/63ee2b58c8402_safety.svg" alt="Safety" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/03/13/640fa60956a09_sports-and-recreation.svg" alt="Sports And Recreation" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/03/13/640fa61fdd032_pet-supplies.svg" alt="Pet Supplies" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/03/13/640fa63aa72ed_outdoor-living.svg" alt="Outdoor Living" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/03/13/640fa650387c9_housewares.svg" alt="Housewares" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/03/13/640fa65f7f40f_halloween.svg" alt="Halloween" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/03/13/640fa69427490_christmas.svg" alt="Christmas" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/03/13/640fa6b0571ea_farm-supplies.svg" alt="Farm Supplies" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/03/13/640fa6c7b46c6_footwear.svg" alt="Footwear" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/03/13/640fa6e2a6027_clothing.svg" alt="Clothing" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/03/13/640fa6f369e96_small-appliances.svg" alt="Small Appliances" @click.prevent="prepareIconForUpdate($event, item.name)">
                <img :style="defaultIconFilter" src="https://storage.googleapis.com/content.ezadtv.com/2023/03/16/64138b2d7ed5f_bear.svg" alt="Toy Bear" @click.prevent="prepareIconForUpdate($event, item.name)">
              </div>
              <div class="col-md-2 text-center pr-2 mt-4" v-if="modalSelectedIconPlaceholder">
                <p class="mb-4" style="margin-bottom: 10px; font-weight: bold;">Selected Icon:</p>
                <img :style="defaultIconFilter" :src="modalSelectedIconPlaceholder">
                <hr class="mt-4 mb-2">
                <p class="mt-4 mb-4" style="margin-bottom: 10px; font-weight: bold;">Pick a Color:</p>
                <color-picker :color="modalCustomColorPlaceholder" @update-color="handleColorChange" />
              </div>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <h6 class="text-muted font-weight-bold text-uppercase mt-1" style="font-size: 14px;">Upload an image</h6>
            </template>
            <b-row class="hoverable" @dblclick="$refs.fileUploader.click()" v-b-hover="handleImageBoxHovered" :class="imageBoxIsHovered ? 'bg-light' : ''" align-h="center" align-v="center" style="height: 21vh; border: 1px solid lightgray; margin-top: 20px; margin-bottom: 20px; margin-left: 1px; margin-right: 5px; box-shadow: 8px 8px 5px -5px rgba(0,0,0,0.75);"  @dragover.prevent @drop.prevent="uploadImage($event, item.name)">
              <b-col class="text-center mt-2" v-if="!uploadingImage">
                <h5>Double Click This Box to Upload an Image or Drag and Drop it Here</h5>
                <h5>Use .svg files to get one colour icons like the default ones!</h5>
                <h5>Recommended Size: Any 1:1. Image will be shrinked to 64x64</h5>
                <input type="file" ref="fileUploader" class="d-none" @change="uploadImage"/>
              </b-col>
              <b-col v-else class="text-center">
                <b-spinner variant="info" style="width: 3rem; height: 3rem;"></b-spinner>
                <h3>Uploading...</h3>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
        <div class="row d-flex">
          <h5 class="mb-1 mt-1 ml-4">Set a custom name for the tile (optional):</h5>
          <div class="col-md-5">
            <b-form-input class="mb-2" size="sm" v-model="customTileTitle" placeholder="Enter a custom name (changes will be reflected on refresh)"></b-form-input>
          </div>
        </div>
        <hr class="mt-2 mb-3">
        <div class="d-flex justify-content-end">
          <b-button variant="outline-danger" @click="hideModal">Close</b-button>
          <b-button v-if="tabIndex === 0" class="ml-3" variant="primary" @click="updateIcon">Apply Changes</b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import AdminApiService from '@/api-services/admin.service';
import { compute } from "@/plugins/rgbToFilter";
import ColorPicker from '@/components/color-picker.vue';

export default {
  name: 'DepartmentItemForWidget',
  props: {
    item: {
      type: Object,
      default: null
    },
    swiper: {
      default: false
    },
    editable: {
      default: false
    }
  },
  components: {
    'color-picker': ColorPicker
  },
  data() {
    return {
      loaded: false,
      selectedIcon: '',
      filteredColor: '',
      uploadingImage: false,
      colorFilter: '',
      customTileTitle: '',
      selectedCustomSvgFilter: '',
      modalSelectedIconPlaceholder: '',
      modalCustomColorPlaceholder: this.$store.state.settings.colors.primary,
      selectedIconEvent: '',
      selectedIconName: '',
      tabIndex: 0,
      imageBoxIsHovered: false
    };
  },
  created() {
    this.intSelectedIcon = this.selectedIcon;
  },
  async mounted() {
    if(this.swiper) {
      this.loaded = true;
    }
    this.getColorFilter(this.$store.state.settings.colors.primary);
    if(this.editable) {
      this.setColorFilterAdminSide();
    }
    if(!this.editable) {
      this.setColorFilterCustomerSide();
    }
  },
  computed: {
    defaultIconFilter() {
      return {
        'filter': this.filteredColor.filterRaw
      };
    },
    inStockOnlyParam() {
      return this.$store.state.settings.products.filterShowOutOfStock ? 0 : 1;    
    },
  },
  methods: {
    handleImageBoxHovered(hovered) {
      this.imageBoxIsHovered = hovered;
    },
    handleColorChange(color) {
      this.modalCustomColorPlaceholder = color;
    },
    getColorFilter(color) {
      do {
        this.filteredColor = compute(color).result;
      } while (this.filteredColor.loss > 2);
    },
    setColorFilterAdminSide(color = null) {
      if(color) {
        return this.colorFilter = color;
      }
      if(this.$refs.departmentImageAdmin.attributes.src != undefined && this.$refs.departmentImageAdmin.attributes.src.value.endsWith('svg')) {
        return this.colorFilter = {'filter': this.filteredColor.filterRaw};
      }
      return this.colorFilter = {'filter': ''};
    },
    setColorFilterCustomerSide(color = null) {
      if(color) {
        return this.colorFilter = color;
      }
      if(this.$refs.departmentImageCustomer.attributes.src != undefined && this.$refs.departmentImageCustomer.attributes.src.value.endsWith('svg')) {
        return this.colorFilter = {'filter': this.filteredColor.filterRaw};
      }
      if(this.$refs.departmentImageCustomer2.attributes.src != undefined && this.$refs.departmentImageCustomer2.attributes.src.value.endsWith('svg')) {
        return this.colorFilter = {'filter': this.filteredColor.filterRaw};
      }
      return this.colorFilter = {'filter': ''};
    },
    urlFriendly(value) {
      return value.replace(/[ /]/g, '+');
    },
    showModal(item) {
      if(item.hex_color) {
        this.modalCustomColorPlaceholder = item.hex_color;
      }
      this.modalSelectedIconPlaceholder = this.selectedIconEvent.target ? this.selectedIconEvent.target.src : item.image_url;
      this.selectedIconName = item.name;
      this.$refs['my-modal'].show();
    },
    hideModal() {
      this.$refs['my-modal'].hide();
      this.selectedCustomSvgFilter = '';
      this.selectedIconEvent = '';
      this.selectedIconName = '';
      this.modalSelectedIconPlaceholder = '';
    },
    prepareIconForUpdate($event, name) {
      this.selectedIconEvent = $event;
      this.selectedIconName = name;
      this.modalSelectedIconPlaceholder = this.selectedIconEvent.target.src;
    },
    updateTileTitle(name){
      const emitData = {
        customTileName: this.customTileTitle,
        departmentName: name
      };
      this.$emit('updateTileTitle', emitData);
    },
    updateIcon(imgUrl = null){
      if(this.selectedIconEvent === '' && imgUrl === null && this.modalSelectedIconPlaceholder === '') {
        this.$swal({
          title: 'Please select either an icon or an image of your own',
          type: 'warning',
        });
        return;
      }
      const emitData = {
        selectedIcon: this.modalSelectedIconPlaceholder.length ? this.modalSelectedIconPlaceholder : imgUrl,
        departmentName: this.selectedIconName
      };
      this.selectedIcon = emitData.selectedIcon;

      this.selectedCustomSvgFilter = compute(this.modalCustomColorPlaceholder).result;
      if(this.selectedCustomSvgFilter != '') {
        emitData.hexColor = this.modalCustomColorPlaceholder;
        emitData.colorFilter = this.selectedCustomSvgFilter.filter;
        this.setColorFilterAdminSide(this.selectedCustomSvgFilter.filter);
        this.setColorFilterCustomerSide(this.selectedCustomSvgFilter.filter);
      }

      this.$emit('updateIcon', emitData);
      if(this.customTileTitle !== '') {
        this.updateTileTitle(emitData.departmentName);
      }
      this.colorFilter = {'filter': this.filteredColor.filterRaw};

      this.hideModal();
    },
    uploadImage(evt) {
      const file = this.checkFile(evt);
      this.uploadingImage = true;
      AdminApiService.uploadImage(file).then(response => {
        this.updateIcon(response.data.url);
        this.colorFilter = {'filter': ''};
        this.uploadingImage = false;
      }).catch(err => {
        alert('Something went wrong while uploading your image.');
      });
    },
    checkFile(evt) {
      const file = evt.dataTransfer ? evt.dataTransfer.files[0] : evt.target.files[0];
      if(file.name.includes(' ')) {
        var newName = file.name.replace(/ /g, "_");
        var newFile = new File([file], newName, {type: file.type});
        return newFile;
      } else {
        return file;
      }
    },
    remove(item) {
      this.$emit('remove', item);
    },
  },
};
</script>

<style lang="scss" scoped>
  .edit-buttons {
    background: gainsboro;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    transform: scale(0.85);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .department-item {
    position: relative;
    width: auto;
    height: 100%;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    cursor: pointer;

    &:hover{
      text-decoration: none;
    }
  }

  .departments {
    display: flex;
    width: 120px;
    height: 120px;
    text-align: center;
    padding: 20px 10px;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 15px;
    border: 2px solid lightgrey;

    .loader-wrapper {
      height: 150px;
    }

    img.department-image {
      display: block;
      max-height: 130px;
      // min-height: 130px;
      margin: 0 auto;
      margin-bottom: 30px;
    }

    h6 {
      color: var(--text);
      font-weight: 600;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-bottom: 0;
    }
  }
  .card {
    border: none;
    box-shadow: 0 14px 10px 0 rgba(34,44,73, .04);
    border-radius: 13px;
    border: 1px solid #E8E8E8;

    .card-body {
      .product {
        .edit-product {
          position: relative;
          top: -10px;
          left: 0;
          margin-left: 5px !important;

          a {
            margin: 0 3px;

            img {
              width: 25px;
              height: 25px;
            }
          }
        }
      }
    }
  }

  .icons-container {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .icons-container > * {
    padding: 15px;
    cursor: pointer;
  }

  .color-box {
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    cursor: pointer;
    box-shadow: 2px 2px 8px 0px rgba(0,0,0,0.62);
  }

  .upload-image.box:hover {
    background-color: gray;
  }

  @media screen and (max-width: 576px) {
    .departments {
      img.department-image {
        margin-bottom: 0;
      }
      h6 {
        margin-top: 12px;
        margin-bottom: 0;
      }
    }
  }
</style>
