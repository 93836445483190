<template>
  <div class="megamenu h-100">
    <div class="d-flex align-items-center h-100 nav-link" :style="{fontSize: navFontSize}">
      {{ title }}
      <svg width="10" height="6" xmlns="http://www.w3.org/2000/svg" class="ml-1 caret"><g data-v-0aa462e6="" fill="none" fill-rule="evenodd"><path data-v-0aa462e6="" data-v-37000717="" d="M-226-22h1440v1459H-226z"></path><path data-v-0aa462e6="" data-v-37000717="" d="M-226-24h1440v53H-226z"></path><path data-v-0aa462e6="" data-v-37000717="" d="M7.5 1.5l-3 3-3-3" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path></g></svg>
    </div>
    <div class="menu text pt-3 pb-4" :style="{ background: parseColor(menu.background) }" :class="darkBackground ? 'light' : ''">
      <div class="container">
        <div class="row flex-grow-1">
          <div class="col-md-3" v-for="(c, i) in columns" :key="`c-${i}`">
            <ul v-if="c.links && c.links.length">
              <li class="my-1" v-for="(l, ii) in c.links" :key="`l-${i}-${ii}`">
                <template v-if="l.image">
                  <div class="position-relative">
                    <img class="mw-100" :src="l.image" :alt="`Megamenu image for ${c.title}`">
                  </div>
                </template>
                <template v-else>
                  <a v-if="l.link" :href="l.link" target="_blank">
                    <h6 v-if="l.title" class="font-weight-bold text-uppercase" :class="{ 'mt-4' : ii }">{{ l.name }}</h6>
                    <span v-else>{{ l.name }}</span>
                  </a>
                  <template v-else>
                    <h6 v-if="l.title" class="font-weight-bold text-uppercase" :class="{ 'mt-4' : ii }">{{ l.name }}</h6>
                    <span v-else>{{ l.name }}</span>
                  </template>
                </template>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-if="menu.callToAction && menu.callToAction.label" class="d-flex justify-content-center mt-3">
        <a class="btn btn-primary" :href="menu.callToAction.link" target="_blank">
          {{ menu.callToAction.label }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'MegaMenu',
    props: {
      title: {
        default: ''
      },
      menu: {
        default: null
      }
    },
    data() {
      return {
      };
    },
    computed: {
      columns() {
        return this.menu.columns.filter(e => e.title || e.links.length || e.image);
      },
      darkBackground() {
        if(!this.menu.background) return false;
        let bg = this.menu.background == 'white' ? 'ffffff' : this.menu.background == 'black' ? '000000' : this.menu.background;
        var r, g, b, hsp;
        let color = +(`0x${bg.slice(1).replace(bg.length < 5 && /./g, '$&$&')}`);
        r = color >> 16;
        g = color >> 8 & 255;
        b = color & 255;
        hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
        console.log(bg, hsp>127.5 ? false : true);
        return hsp>127.5 ? false : true;
      },
      navFontSize(){
        if(this.$store.state.settings && this.$store.state.settings.navStyle && this.$store.state.settings.navStyle.links && this.$store.state.settings.navStyle.links.fontSize){
          return `${this.$store.state.settings.navStyle.links.fontSize}px !important`;
        }
        return '';
      }
    },
    methods: {
      parseColor(color) {
        let re = /^([0-9A-F]{3}){1,2}$/i;
        return re.test(color) ? `#${color}` : `var(--${color})`;
      }
    },
  };
</script>
<style lang="scss" scoped>
  .megamenu {
    z-index: 10;
    > .nav-link,
    > .nav-link:hover {
      color: var(--navigationLinkColor);
    }
    .menu {
      cursor: auto !important;
      font-size: 14px;
      position: absolute;
      display: none;
      background: #fff;
      width: 100%;
      left: 0;
      min-height: calc(var(--vh, 1vh) * 50 - 100px);
      z-index: 10;
      box-shadow: 0 5px 10px rgba(0,0,0,.1);
      color: #000 !important;
      &.light {
        color: #fff !important;
        .nav-link {
          color: #fff !important;
          background: none;
        }
        a {
          color: #fff !important;
        }
        .text-primary {
          color: #fff !important;
        }
      }
      ul {
        list-style: none;
        padding: 0;
      }
    }
    &:hover {
      .menu {
        display: block;
      }
    }
  }
</style>
